.contents {
  max-height: 100vh;
  transition: 0.25s all ease-in;
}

input[type=checkbox]:checked + .contents {
  max-height: 0;
  transition: 0.25s all ease-out;
  padding: 0;
}

.open-chevron {
  transform: rotate(-45deg);
  transition: transform 0.25s ease-in;
}

.closed-chevron {
  transform: rotate(135deg);
  transition: transform 0.25s ease-out;
}

.reverse-svg {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.right-ear {
  margin-right: 0.25em;
}
.left-ear {
  margin-left: 0.25em;
}