.hide-message, .show-message {
    font-style: italic;
    font-size: .9em;
    margin-top: 1em;
    text-align: center;
    transition: opacity .2s ease-out;
    color: black;
}
  
.hide-message {
  opacity: 0;
}

.show-message {
  opacity: 1;
}