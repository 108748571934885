:root {
  --light-gray: #ecebee;
  --medium-gray: #e5e5e5;
  --medium-dark-gray: #bfbfbf;
  --green-yellow: #c7d49c;
  --green-yellow-darker: #b2c084;
  --purple: #3c2f66;
  --purple-hover: #4c3f77;
  --dark-gray: #989898;
  --darkest-gray: #7f7f7f;
  --almost-black: #222;
  --blue: #3d6cab;
  --light-blue: #5b89be;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans';
  background-color: var(--medium-gray);
  font-size: 16px;
}


@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./fonts/OpenSans-VariableFont_wdth\,wght.ttf) format('opentype');
}

.app {
  background-color: var(--medium-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-logo {
  height: 65px;
  min-height: 65px;
  pointer-events: none;
}

.main-content {
  min-height: 100vh;
}

/* media queries */
@media (min-width: 550px) {
  .main-content {
    min-width: 65vw;
    max-width: 80vw;
  }
}

@media (max-width: 549px) {
  .main-content {
    width: 100%;
    font-size: 18px;
  }
}